<template>
  <v-main>
    <Header
      :title="'Tabelas'"
      :subtitle="'Gerenciamento das atualizações dos leiautes do e-Social'"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasUpdateInfo="false"
      :hasAddButton="false"
    />
    <div id="tables-container">
      <v-data-table
          :headers="headers"
          :items="tables"
          class="elevation-1" 
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [50, 100, 250],
            itemsPerPageText: `Tabelas por página: `,
            disableItemsPerPage: tables.length < 50,
          }"
      >
        <template v-slot:footer.page-text="items">
          {{  items.pageStart }} - {{ items.pageStop }} de {{ totalTables }}  tabelas 
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon 
                  v-bind="attrs"
                  v-on="on"
                  :to="item.path"
                > 
                  <span class="material-icons-outlined" style=" color: #442D96 !important;font-size: 16px; ">visibility </span>
                </v-btn>
              </template>
            <span>Visualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-main>
</template>
<script>
import tablesMock from './tablesMock.json';
import eSocialService from '@/services/eSocialService.js';
export default {
name: 'Tables',
components: {
  Header: () => import('../../commons/Header.vue'),
},
created() {
  this.fetch();
},
updated() {
  this.customize();
},
data() {
  return {
    tables: [],
    totalTables:0,
    loading: false,
    headers: [
      { text: 'Nome', align: 'start', value: 'name', width: '780px' },
      { text: 'Atualização', align: 'start', value: 'update', width: '200px', sortable: false  },
      { text: 'Alterado por', align: 'start', value: 'updatedBy', width: '169px', sortable: false  },
      { text: 'Ações', align: 'center',  value: 'actions', width: '72px', sortable: false },
    ],
    options: {},
  };
},
computed: {  
  itemsBreadCrumb() {
      let itemsBreadCrumb = [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Tabelas',
          disabled: true,
          active: true
        }
      ];
      return itemsBreadCrumb;
    },
},
watch: {
  options: {
    handler() {
      this.fetch();
    },
  },
},
methods: {
  async fetch() {    
    const response = await eSocialService.rubricAutomationConfig.historyLatest();
    this.tables = tablesMock.tables;
    this.tables[0].update = response.data.rubric_nature.date.replace(' - ', ' às ');
    this.tables[0].updatedBy = response.data.rubric_nature.user;
    this.tables[1].update = response.data.rubric_type.date.replace(' - ', ' às ');
    this.tables[1].updatedBy = response.data.rubric_type.user;
    this.tables[2].update = response.data.inss_incidence.date.replace(' - ', ' às ');
    this.tables[2].updatedBy = response.data.inss_incidence.user;
    this.tables[3].update = response.data.irrf_incidence.date.replace(' - ', ' às ');
    this.tables[3].updatedBy = response.data.irrf_incidence.user;
    this.tables[4].update = response.data.fgts_incidence.date.replace(' - ', ' às ');
    this.tables[4].updatedBy = response.data.fgts_incidence.user;
    this.tables[5].update = response.data.cprp_incidence.date.replace(' - ', ' às ');
    this.tables[5].updatedBy = response.data.cprp_incidence.user;
    this.totalTables = tablesMock.tables.length;
    this.loading = false;
  },
   
  customize() {
    let tableLines = document.querySelectorAll('#tables-container tbody tr');
    if (tableLines && tableLines.length > 1) {
      for (let i = 0; i < tableLines.length; i++) {          
        tableLines[i].style.backgroundColor = '#FFFFFF';
        if (i % 2) {
          tableLines[i].style.backgroundColor = '#F7F7FD';
        }
      }
    }
  },
}
}
</script>
<style scoped>
::v-deep .v-data-table-header {
text-align: start;
background-color: #e7e7fa;
cursor: default;
}

::v-deep .v-data-table-header tr th {
line-height: 18.62px;
letter-spacing: 1%;
height: 51px;
flex-direction: row;
align-items: flex-start;
margin-right: 1px;
cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
color: var(--v-primary-base);
font-family: 'Roboto';
font-weight: 700;
font-size: 14px;
cursor: default;
}
::v-deep .v-data-table-header tr th.text-center span {
color: var(--v-primary-base);
font-family: 'Roboto';
font-weight: 700;
font-size: 14px;
cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr th {
  border-bottom: 0px !important;
}
::v-deep .v-data-footer__select {
font-size: 16px !important;
color: #52526b !important;
font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
margin: 0px 64px 0px 0px;
font-size: 16px !important;
color: #52526b !important;
font-weight: normal !important;
}
::v-deep .v-data-footer {
padding-left: 16px;
padding-right: 16px;
}
::v-deep .v-data-footer__icons-before .v-btn {
background-color: #e7e7fa;
color: #8686a2 !important;
height: 24px !important;
width: 24px !important;
border-radius: 24px !important;
margin-right: 0;
padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
background-color: #e7e7fa;
color: var(--v-primary-base);
height: 24px !important;
width: 24px !important;
border-radius: 24px !important;
margin-left: 0px !important;
margin-right: 0;
padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
background-color: #e7e7fa;
color: var(--v-primary-base);
height: 24px !important;
width: 24px !important;
border-radius: 24px !important;
margin-right: 8px !important;
padding: 0;
}
::v-deep .v-data-footer .v-input__slot {
background: #e3ffee !important;
color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-data-footer__select .v-select {
background: #ffffff !important;
color: var(--v-primary-base);
margin-top: 16px;
margin-bottom: 0px;
}

::v-deep .v-data-footer__icons-after .v-btn {
background-color: white !important;
border-radius: 100% !important;
padding: 0 !important;
}

::v-deep .v-data-footer__icons-after .v-btn:active {
background-color: #2a1c4f !important;
color: white !important;
padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
background-color: #2a1c4f !important;
color: white !important;
padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
background-color: white !important;
border-radius: 100% !important;
padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
background-color: #e3ffee !important;
color: var(--v-primary-base);
border-radius: 100% !important;
box-shadow: none !important;
transition: none !important;
opacity: 1 !important;
padding: 0 !important;
}

::v-deep .v-data-footer .v-select__slot .v-input__append-inner {
padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
font-family: Roboto !important;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.01em;
text-align: left;
color: #8686a2 !important;
}

::v-deep .v-data-footer .v-select__slot {
background-color: #e7e7fa;
height: 48px;
width: 83px;
border-radius: 4px;
border: 1px solid #e7e7fa;
padding-left: 15px;
padding-right: 10px;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
background-color: #e3ffee !important;
border-radius: 100% !important;
box-shadow: none !important;
opacity: 1 !important;
transition: none !important;
padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
padding: 0 !important;
background-color: #e7e7fa !important;
border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
background-color: #e7e7fa !important;
padding: 0 !important;
border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled .v-btn__content .v-icon{
  margin-right: 0px !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled .v-btn__content .v-icon{
  margin-right: 4px !important;
}
::v-deep .v-input__slot::before {
border: none !important;
}
::v-deep .v-input__slot::after {
border: none !important;
}
.v-application .v-btn i {
  margin-right: 8px;
} 
::v-deep #tables-container{
  border-radius: 4px !important;
  border: 1px solid #E7E7FA !important; 
} 
.v-application .elevation-1{
  box-shadow: 0px 0px !important;
}
</style>