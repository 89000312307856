var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: "Tabelas",
          subtitle: "Gerenciamento das atualizações dos leiautes do e-Social",
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasUpdateInfo: false,
          hasAddButton: false,
        },
      }),
      _c(
        "div",
        { attrs: { id: "tables-container" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.tables,
              loading: _vm.loading,
              options: _vm.options,
              "footer-props": {
                "items-per-page-options": [50, 100, 250],
                itemsPerPageText: `Tabelas por página: `,
                disableItemsPerPage: _vm.tables.length < 50,
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "footer.page-text",
                fn: function (items) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(items.pageStart) +
                        " - " +
                        _vm._s(items.pageStop) +
                        " de " +
                        _vm._s(_vm.totalTables) +
                        " tabelas "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "", to: item.path } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "material-icons-outlined",
                                          staticStyle: {
                                            color: "#442D96 !important",
                                            "font-size": "16px",
                                          },
                                        },
                                        [_vm._v("visibility ")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Visualizar")])]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }